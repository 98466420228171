import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Splash from './components/Splash';
import Start from './components/Start';
import SellerList from './components/Sellers/SellerList';
import SellerCreate from './components/Sellers/SellerCreate';
import SellerEdit from './components/Sellers/SellerEdit';
import SellerView from './components/Sellers/SellerView';

import './App.css';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewTitle: '',
    };
  }

  render() {
    
    return (
      <Router forceRefresh={false}>
        <div className="App">
          <div className="contentArea">
            <Switch>
              <Route exact={true} path="/" component={Splash} />
              <Route path="/start" component={Start} />
              <Route path="/seller-list" component={SellerList} />
              <Route path="/seller-create" component={SellerCreate} />
              <Route path="/seller-edit/:id" component={SellerEdit} />
              <Route path="/seller-view/:id" component={SellerView} />
             </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
