import React from 'react';
import Axios from 'axios';
import API_URL from '../Api/ApiUrl';
import Flexbox from 'flexbox-react';
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, Card, CardHeader, CardBody, Alert } from 'reactstrap';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import Select from "react-dropdown-select";
import Icon from "../Icons/icon";
import {
    BrowserView,
    MobileView,
    isMobile,
    isSafari
} from "react-device-detect";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import mapStyles from './mapStyles';
import './Seller.css';

class SellerCreate extends React.Component {

    constructor(props) {
        super(props);

        this.lastCenter = {
            lat: 35.70092132333425,
            lng: 51.391260847449296
        }

        this.state = {
            showSuccess: false,
            showDanger: false,
            userId: '',
            name: '',
            family: '',
            gender: 1,
            mobile: '',
            shop: '',
            region: '',
            address: '',
            latitude: '',
            longitude: '',
            user_code: '',
            nationalCode: '',
            phoneNumber: '',
            isMale: true,
            gndrName: 'مرد',
            avatarSource: null,
            avatarBase64: null,
            validate: {
                nameState: '',
                familyState: '',
                mobileState: '',
                shopState: '',
                regionState: '',
                addressState: '',
            },
            goods: [],
            itemList: [],
            selectedType: 0,
            selectedItem: 0,
            isSetCurrent: false,
            mapCenter: {
                lat: 35.70092132333425,
                lng: 51.391260847449296
            },
            currentPosition: {
                lat: 0,
                lng: 0
            },
            currentLatLng: {
                lat: 0,
                lng: 0
            },
            locations: [],
            isMarkerShown: false,
            isDataSaved: false,
            isTypeWarn: false,
            isItemWarn: false,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        //  this.handleMapClick = this.handleMapClick.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem("_curntUser") !== null && sessionStorage.getItem("_curntUser") !== '') {
            this.setState({ userId: sessionStorage.getItem('_curntUser') });
            this.showCurrentLocation();
        } else {
            this.props.history.push({ pathname: '/start' });
        }
    }

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.setState(prevState => ({
                        currentPosition: {
                            ...prevState.currentLatLng,
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        },
                        isSetCurrent: true,
                        isMarkerShown: true
                    }))
                }
            )
        }
    }

    handleChange() {
        const { gender } = this.state;
        if (gender === 1) {
            this.setState({ gender: 2 });
            this.setState({ gndrName: 'زن' });
        }
        if (gender === 2) {
            this.setState({ gender: 1 });
            this.setState({ gndrName: 'مرد' });
        }
    }

    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
        if (event.target.value !== 0) {
            this.setState({ isTypeWarn: false });
            this.getItemsByType(event.target.value);
        } else {
            this.setState({ isTypeWarn: true });
        }
    };

    handleChangeItem = (values) => {
        this.setState({ goods: [] });
        values.map((value) => {
            console.log(value.id);
            this.setState({ goods: [...this.state.goods, value.id] })
        })
    };


    // handleChangeItem = (event) => {
    //     this.setState({ selectedItem: event.target.value });
    //     if (event.target.value !== 0) {
    //         this.setState({
    //             goods: this.state.goods.concat(parseInt(event.target.value))
    //         });
    //         this.setState({ isItemWarn: false });
    //     } else {
    //         this.setState({ isItemWarn: true });
    //     }
    // };

    getItemsByType(type) {
        Axios.get(API_URL + 'getProductList/' + type)
            .then(response => {
                console.log(response.data);
                this.setState({ itemList: response.data });
            }).catch(error => {
                console.log(error);
            });
    }

    validateName(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.nameState = 'has-success'
        } else {
            validate.nameState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateFamily(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.familyState = 'has-success'
        } else {
            validate.familyState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateMobile(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.mobileState = 'has-success'
        } else {
            validate.mobileState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateShop(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.shopState = 'has-success'
        } else {
            validate.shopState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateRegion(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.regionState = 'has-success'
        } else {
            validate.regionState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateAddress(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.addressState = 'has-success'
        } else {
            validate.addressState = 'has-danger'
        }
        this.setState({ validate })
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    onSubmit = (eventData) => {
        eventData.preventDefault();
        if (sessionStorage.getItem("_curntUser") !== null && sessionStorage.getItem("_curntUser") !== '') {
            this.setState({ userId: sessionStorage.getItem('_curntUser') });
            const { validate, name, family, mobile, shop, region, address, selectedType } = this.state;
            if (name !== '' && family !== '' && mobile !== '' && shop !== ''
                && region !== '' && address !== '' && selectedType !== 0) {

                const seller = {
                    'id': 0,
                    'name': this.state.name,
                    'family': this.state.family,
                    'gender': parseInt(this.state.gender),
                    'mobile': this.state.mobile,
                    'bussines_type': parseInt(this.state.selectedType),
                    'sale_type': 1,
                    'goods': this.state.goods,
                    'shop_title': this.state.shop,
                    'region': this.state.region,
                    'address': this.state.address,
                    'locLat': this.state.latitude,
                    'locLng': this.state.longitude,
                    'user_admin': this.state.userId,
                }

                console.log(seller);

                Axios({
                    url: API_URL + 'registerSeller',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: seller,
                }).then((res) => {
                    ToastsStore.success('ثبت شد');
                    this.setState({ isDataSaved: true })
                    this.setState({ showSuccess: true, showDanger: false });
                });
            } else {
                if (name === '')
                    validate.nameState = 'has-danger'
                if (family === '')
                    validate.familyState = 'has-danger'
                if (mobile === '')
                    validate.mobileState = 'has-danger'
                if (shop === '')
                    validate.shopState = 'has-danger'
                if (region === '')
                    validate.regionState = 'has-danger'
                if (address === '')
                    validate.addressState = 'has-danger'

                if (selectedType === 0) {
                    this.setState({ isTypeWarn: true })
                }

                // if (this.state.temp.length === 0) {
                //     this.setState({ isItemWarn: true })
                // }

                this.setState({ validate })
            }
        } else {
            this.props.history.push({ pathname: '/start' });
        }
    }

    goToList() {
        const { history } = this.props;
        history.push('/seller-list');
    }

    setMapCenter() {
        if (this.state.isSetCurrent) {
            this.setState({ mapCenter: this.state.currentPosition })
        } else {
            this.setState({ mapCenter: this.state.currentPosition })
        }
    }

    onMapClick = (event) => {
        this.setState(() => ({
            isSetCurrent: true,
            mapCenter: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            },
            currentPosition: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            }
        }));

        this.setState({ latitude: event.latLng.lat() })
        this.setState({ longitude: event.latLng.lng() })

        // console.log(event.latLng.lat());
        // console.log(event.latLng.lng());
    }

    render() {
        const { name, family, mobile, shop, region, address } = this.state;
        const { selectedType, selectedItem, itemList } = this.state;
        let ref = null;
        let MapWrapped = withScriptjs(withGoogleMap(props => (
            <GoogleMap
                ref="map"
                ref={(mapRef) => ref = mapRef}
                onCenterChanged={() => {
                    if (this.state.panelStatus === 1) {
                        var center = ref.getCenter().toJSON();
                        console.log(center)
                        this.lastCenter = center;
                    }
                }}

                isMarkerShown={this.state.isMarkerShown}
                currentLocation={this.state.currentPosition}

                onClick={this.onMapClick.bind(this)}

                defaultZoom={16}
                defaultCenter={this.state.mapCenter}
                options={{
                    styles: mapStyles,
                    mapTypeControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    fullscreenControl: true,
                }}>

                <Marker
                    mapTypeControl={false}
                    position={this.state.mapCenter}
                    icon={{
                        scaledSize: new window.google.maps.Size(39, 56),
                        anchor: new window.google.maps.Point(20, 28),
                    }}
                />
            </GoogleMap>)
        ));

        return (
            <div style={{ textAlign: 'right', direction: 'rtl', paddingTop: 10 }}>
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Container>
                                        <Row>
                                            <Col xs="6">معرفی فروشنده جدید</Col>
                                            <Col xs="6" className="colCss">
                                                <div className="btnIndex" onClick={this.goToList.bind(this)}>
                                                    <Icon icon={'list2'} size={14} color="#fff" />
                                                    <span style={{ marginRight: 10, color: '#fff' }}>فهرست</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardHeader>
                                <CardBody>
                                    <Flexbox flexDirection="column">
                                        <Flexbox flexGrow={1} justifyContent="flex-end">
                                            <Flexbox flexDirection="row" style={{ width: '100%', padding: 20 }}>
                                                <div>
                                                    <Form>
                                                        <FormGroup>
                                                            <Label>نام:</Label>
                                                            <Input type="text" name="name" id="name"
                                                                valid={this.state.validate.nameState === 'has-success'}
                                                                invalid={this.state.validate.nameState === 'has-danger'}
                                                                onChange={(e) => {
                                                                    this.validateName(e)
                                                                    this.handleInputChange(e)
                                                                }}
                                                                value={name} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>نام خانوادگی:</Label>
                                                            <Input type="text" name="family" id="family"
                                                                valid={this.state.validate.familyState === 'has-success'}
                                                                invalid={this.state.validate.familyState === 'has-danger'}
                                                                onChange={(e) => {
                                                                    this.validateFamily(e)
                                                                    this.handleInputChange(e)
                                                                }}
                                                                value={family} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>جنسیت:</Label>{'  '}
                                                            <Button onClick={this.handleChange.bind(this)} type="button">{this.state.gndrName}</Button>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>شماره همراه:</Label>
                                                            <Input type="number" name="mobile" id="mobile"
                                                                valid={this.state.validate.mobileState === 'has-success'}
                                                                invalid={this.state.validate.mobileState === 'has-danger'}
                                                                onChange={(e) => {
                                                                    this.validateMobile(e)
                                                                    this.handleInputChange(e)
                                                                }}
                                                                value={mobile} maxLength={10}
                                                                style={{ direction: 'ltr' }} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>نوع فعالیت:</Label><br />
                                                            <select className="drpdown" name="drpType" value={selectedType} onChange={(e) => this.handleChangeType(e)}>
                                                                <option value="0">انتخاب کنید...</option>
                                                                <option value="1">مایحتاج روزانه</option>
                                                                <option value="2">فروشگاهی</option>
                                                                <option value="3">خدماتی</option>
                                                            </select>
                                                            {this.state.isTypeWarn ? (
                                                                <span style={{ fontSize: 40, color: '#ee0000' }}> * </span>) : null}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>عنوان فعالیت:</Label><br />
                                                            <Select
                                                                multi
                                                                style={{ width: 300, textAlign: 'right', borderRadius: 5 }}
                                                                searchable="true"
                                                                placeholder="انتخاب کنید..."
                                                                options={itemList}
                                                                direction="rtl"
                                                                labelField="title"
                                                                valueField="id"
                                                                searchBy="title"
                                                                sortBy="title"
                                                                onChange={(values) => this.handleChangeItem(values)}
                                                                noDataLabel="موردی پیدا نشد"
                                                                disabled={selectedType > 0 ? false : true}
                                                            />
                                                            {this.state.isItemWarn ? (
                                                                <span style={{ fontSize: 40, color: '#ee0000' }}> * </span>) : null}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>عنوان فروشگاه:</Label>
                                                            <Input type="text" name="shop" id="shop"
                                                                valid={this.state.validate.shopState === 'has-success'}
                                                                invalid={this.state.validate.shopState === 'has-danger'}
                                                                onChange={(e) => {
                                                                    this.validateShop(e)
                                                                    this.handleInputChange(e)
                                                                }}
                                                                value={shop} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>محله، منطقه:</Label>
                                                            <Input type="text" name="region" id="region"
                                                                valid={this.state.validate.regionState === 'has-success'}
                                                                invalid={this.state.validate.regionState === 'has-danger'}
                                                                onChange={(e) => {
                                                                    this.validateRegion(e)
                                                                    this.handleInputChange(e)
                                                                }}
                                                                value={region} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>آدرس:</Label>
                                                            <Input type="text" name="address" id="address"
                                                                valid={this.state.validate.addressState === 'has-success'}
                                                                invalid={this.state.validate.addressState === 'has-danger'}
                                                                onChange={(e) => {
                                                                    this.validateAddress(e)
                                                                    this.handleInputChange(e)
                                                                }}
                                                                value={address} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <br />
                                                            <Label>موقعیت جغرافیایی:</Label>
                                                            <Input type="hidden" name="latitude" id="latitude" />
                                                            <Input type="hidden" name="longitude" id="longitude" />
                                                        </FormGroup>
                                                    </Form>
                                                    <BrowserView>
                                                        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_RIGHT} />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
                                                    </MobileView>
                                                </div>
                                            </Flexbox>
                                        </Flexbox>
                                        <Flexbox flexGrow={1} flexDirection="column" justifyContent="center">
                                            <Container>
                                                <Row>
                                                    <Col xs={12} style={{ padding: 5 }}>
                                                        {/* map OK */}
                                                        <div style={{ height: 400 }}>
                                                            <MapWrapped
                                                                googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyAzo9Xzk5QwuAixqF8Kxdxp1zgMfL2DtKA&v=3.exp&libraries=geometry,drawing,places&language=fa'}
                                                                loadingElement={<div style={{ height: '100%' }} />}
                                                                containerElement={<div style={{ height: '100%' }} />}
                                                                mapElement={<div style={{ height: '100%' }} />} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Flexbox>
                                        <Flexbox flexGrow={1} flexDirection="column" justifyContent="center">
                                            <div style={{ textAlign: 'center' }}>
                                                <div style={{ display: this.state.isDataSaved ? 'none' : 'inline' }}>
                                                    <br /><br />
                                                    <div className="btnSave" onClick={this.onSubmit}>
                                                        <Icon icon={'check'} size={18} color="#fff" />
                                                        <span style={{ marginRight: 10, color: '#fff' }}>ثبت</span>
                                                    </div>
                                                </div>
                                                <br /><br />
                                                <Alert className="alertWidth" isOpen={this.state.showSuccess} color="success">اطلاعات با موفقیت ثبت شد</Alert>
                                                <Alert className="alertWidth" isOpen={this.state.showDanger} color="danger">خطا در ثبت اطلاعات</Alert>
                                            </div>
                                        </Flexbox>
                                    </Flexbox>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default SellerCreate;