import React from 'react';
import Axios from 'axios';
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import API_URL from '../Api/ApiUrl';
import Flexbox from 'flexbox-react';
import kbcLogo from '../../assets/images/logofa.png';
import './Start.css';

class Start extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            userPass: '',
            showLoading: false,
            validate: {
                userState: '',
                passState: '',
            },
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.doCheckUser = this.doCheckUser.bind(this);
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    validateUser(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.userState = 'has-success'
        } else {
            validate.userState = 'has-danger'
        }
        this.setState({ validate })
    }

    validatePass(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.passState = 'has-success'
        } else {
            validate.passState = 'has-danger'
        }
        this.setState({ validate })
    }

    doCheckUser(eventData) {
        eventData.preventDefault();
        const { validate, userName, userPass } = this.state;
        if (userName !== '' && userPass !== '') {

            const admin = {
                'username': userName,
                'password': userPass,
            }

            console.log(admin);

            Axios({
                method: 'post',
                url: API_URL + 'checkAdmin',
                timeout: 8000,  // 8 seconds timeout
                headers: { "Content-Type": "application/json" },
                data:  admin
            }).then(response => {
                console.log(response.data.message);
                var _msg = response.data.message;
                if (_msg === 'exist') {
                    sessionStorage.setItem('_curntUser', userName);
                    this.props.history.push({ pathname: '/seller-list' })
                } else {
                    ToastsStore.warning('نام کاربری یا کلمه عبور نادرست است');
                }

            })
                .catch(error => console.error(error))

        } else {
            if (userName === '')
                validate.userState = 'has-danger'
            if (userPass === '')
                validate.passState = 'has-danger'
            this.setState({ validate })
        }
    }

    render() {
        return (
            <div>
                <Flexbox flexDirection="column" minHeight="100vh">
                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                        <img src={kbcLogo} alt="logo" width="110" height="110" />
                    </Flexbox>

                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                        <div><strong>ورود به پنل مدیریت فروشندگان</strong></div>
                    </Flexbox>

                    <Flexbox flexGrow={1} justifyContent="center">
                        <Form>
                            <FormGroup>
                                <div style={{ padding: 10, textAlign: 'right', direction: 'rtl' }}>نام کاربری:</div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Input type="text" name="userName" id="userName"
                                        valid={this.state.validate.userState === 'has-success'}
                                        invalid={this.state.validate.userState === 'has-danger'}
                                        onChange={(e) => {
                                            this.validateUser(e)
                                            this.handleInputChange(e)
                                        }}
                                        maxLength="20" style={{ width: 180, textAlign: 'center' }} />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div style={{ padding: 10, textAlign: 'right', direction: 'rtl' }}>کلمه عبور:</div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Input type="password" name="userPass" id="userPass"
                                        valid={this.state.validate.passState === 'has-success'}
                                        invalid={this.state.validate.passState === 'has-danger'}
                                        onChange={(e) => {
                                            this.validatePass(e)
                                            this.handleInputChange(e)
                                        }}
                                        maxLength="20" style={{ width: 180, textAlign: 'center' }} />
                                </div>

                                <div style={{ height: 10, padding: 10 }} >{this.state.showLoading ? <Spinner color="primary" /> : null}</div>
                            </FormGroup>
                        </Form>
                        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
                    </Flexbox>
                    <Flexbox flexGrow={0.8} justifyContent="center">
                        <Button onClick={this.doCheckUser} style={{ width: 120, height: 40, backgroundColor: '#d45c19' }}>تأیید</Button>
                    </Flexbox>
                </Flexbox>
            </div>
        );
    }
}
export default Start;