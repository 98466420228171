import React from 'react';
import Flexbox from 'flexbox-react';
import { isAndroid, isMobile } from "react-device-detect";
import kbcLogo from '../../assets/images/logo_fa.png';

class Splash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            firstLunch: true,
        }
    }

    async componentDidMount() {
        this.props.history.push({ pathname: '/start' });
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(
                () => { resolve('result') },
                2000
            )
        );
    }

    render() {
        return (
            <div>
                <Flexbox flexDirection="column" minHeight="100vh">
                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                        <img src={kbcLogo} alt="logo" width="110" height="110" />
                    </Flexbox>
                </Flexbox>
            </div>
        );
    }
}
export default Splash;