import React from 'react';
import Axios from 'axios';
import { Button, Table, Container, Row, Col, Card, CardHeader, CardBody, Label, Input, Spinner, Form } from 'reactstrap';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { isMobile } from "react-device-detect";
import Flexbox from 'flexbox-react';
import Modal from 'react-modal';
import Icon from "../Icons/icon";
import API_URL from '../Api/ApiUrl';
import kbclogo from '../../assets/images/kbclogo.png';
import './Seller.css';

Modal.setAppElement('*');
const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(110, 110, 110, 0.30)'
    },
    content: {
        width: 300,
        height: 140,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 10,
        borderRadius: 10
    }
};

const permitStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(110, 110, 110, 0.30)'
    },
    content: {
        width: 400,
        height: 300,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 10,
        borderRadius: 10
    }
};

class SellerList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userList: [],
            sellerList: [],
            searchList: [],
            userId: '',
            recCount: 0,
            selectedUser: -1,
            selectedRecord: 0,
            isUserAdmin: false,
            confirmModalVisible: false,
            permitModalVisible: false,
            showLoading: false,
            topTitle: 'مدیریت فروشندگان',
            Family: '',
            Region: '',
            passwrd1: '',
            passwrd2: 0,
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onChangePassword1 = this.onChangePassword1.bind(this);
        this.onChangePassword2 = this.onChangePassword2.bind(this);
    }

    componentDidMount() {
        const _thisUser = sessionStorage.getItem('_curntUser');
        this.setState({ userId: _thisUser });
        if (_thisUser === 'admin') {
            this.setState({ isUserAdmin: true });
            this.getUserList();
        } else {
            this.getSellerList(_thisUser);
        }
    }

    onChangePassword1(e) {
        this.setState({ passwrd1: e.target.value })
    }

    onChangePassword2(e) {
        this.setState({ passwrd2: e.target.value })
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    getUserList() {
        Axios.get(API_URL + 'getUserList')
            .then(response => {
                console.log(response.data);
                this.setState({ userList: response.data });
            }).catch(error => {
                console.log(error);
            });
    }

    getSellerList(username) {
        this.setState({ showLoading: true })
        Axios.get(API_URL + 'getListSellersByAdmin/' + username)
            .then(response => {
                console.log(response.data);
                this.setState({ sellerList: response.data });
                this.setState({ searchList: response.data });
                this.setState({ recCount: this.state.sellerList.length });
                this.setState({ showLoading: false })
            }).catch(error => {
                console.log(error);
            });
    }

    handleChangeUser = (event) => {
        this.setState({ selectedUser: event.target.value });
        this.getSellerList(event.target.value);
    }

    goToCreate() {
        const { history } = this.props;
        history.push('/seller-create');
    }

    showSeller(id) {
        const { history } = this.props;
        history.push('/seller-view/' + id);
    }

    updateSeller(id) {
        const { history } = this.props;
        history.push('/seller-edit/' + id);
    }

    showConfirm(id) {
        this.setState({ confirmModalVisible: true });
        this.setState({ selectedRecord: id });
    }

    handleDeleteRecord() {
        const { selectedRecord } = this.state;
        Axios.delete(API_URL + 'deleteSeller/' + selectedRecord)
            .then(response => {
                console.log(response.data);
                var _msg = response.data.message;
                if (_msg == 'ok') {
                    this.getSellerList();
                    ToastsStore.success('حذف شد');
                } else {
                    ToastsStore.warning('این فروشنده دارای تراکنش می باشد و حذف امکانپذیر نیست');
                }
            }).catch(error => {
                console.log(error);
            });
        this.setState({ confirmModalVisible: false });
    }

    closeModal() {
        this.setState({ confirmModalVisible: false });
    }

    showUserPermit(usrnme) {
        this.setState({ permitModalVisible: true });
    }

    closePermitModal() {
        this.setState({ permitModalVisible: false });
    }

    onSubmit(eventData) {
        eventData.preventDefault();
        const { passwrd1, passwrd2 } = this.state;
        if (passwrd1.length < 5) {
            ToastsStore.warning('کلمه عبور حداقل باید 5 کاراکتر باشد');
        } else {
            if (passwrd1 !== passwrd2) {
                ToastsStore.warning('تکرار کلمه عبور نادرست است');
            } else {
                const userDto = {
                    'Username': this.state.userId,
                    'Password': this.state.passwrd1
                }

                Axios.put(API_URL + 'changePermit', userDto).then((res) => {
                    console.log(res.data);
                    ToastsStore.success('تغییرات با موفقیت ثبت شد');
                }).catch((err) => {
                    ToastsStore.warning('در حال حاضر امکان تغییر وجود ندارد');
                });
            }
        }
    }

    render() {
        const { userList, sellerList, selectedUser } = this.state;
        return (
            <div style={{ direction: 'rtl', textAlign: 'center' }}>
                <Flexbox flexDirection="row" height="50px" width="100%">
                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                        <span style={{ verticalAlign: 'middle', fontSize: 18, fontWeight: 'bold' }}>{this.state.topTitle}</span>
                    </Flexbox>
                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                        <a href="javascript:" onClick={() => this.showUserPermit(this.state.userId)}><Icon icon={'user'} size={18} color="#000" style={{ marginRight: 5, marginLeft: 5 }} /></a>{' '}
                        <span style={{ verticalAlign: 'middle', fontSize: 14, fontWeight: 'bold', color: '#226622' }}>کاربر:{' '}{this.state.userId}</span>
                    </Flexbox>
                    <Flexbox flexGrow={1} justifyContent="center">
                        <img src={kbclogo} alt="logo" width="48" height="48" />
                    </Flexbox>
                </Flexbox>

                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Container>
                                        <Row>
                                            <Col xs="6" style={{ textAlign: 'right' }}>فهرست فروشندگان</Col>
                                            <Col xs="6" className="colCss">
                                                <div className="btnNew" onClick={this.goToCreate.bind(this)}>
                                                    <Icon icon={'plus-circle'} size={18} color="#fff" />
                                                    <span style={{ marginRight: 10, color: '#fff' }}>جدید</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardHeader>
                                <CardBody>
                                    {this.state.isUserAdmin ? (
                                        <Container>
                                            <Row>
                                                <Col xs="8" md="8" lg="8" style={{ textAlign: 'right', padding: 5 }}>
                                                    <Label> نام کاربر:</Label>{' '}
                                                    <select className="drpdown" value={selectedUser} onChange={this.handleChangeUser}>
                                                        <option value="-1">انتخاب کنید...</option>
                                                        <option value="all">همه موارد</option>
                                                        {userList.map((item) => {
                                                            return (
                                                                <option key={item.id} value={item.username}>{item.username}</option>)
                                                        })}
                                                    </select>
                                                </Col>
                                                <Col xs="4" md="4" lg="4" style={{ textAlign: 'center', padding: 5 }}>
                                                    <span>تعداد رکورد:</span>{' '}{this.state.recCount}
                                                </Col>
                                            </Row>
                                        </Container>
                                    ) : null}
                                    <Container>
                                        <Row>
                                            <Col xs="12" md="12" lg="12" style={{ textAlign: 'right', padding: 5 }}>
                                                <Label> جستجو:</Label>{' '}
                                                <Input type="text" name="Family" id="Family" placeholder="نام خانوادگی" value={this.state.Family} onChange={this.handleInputChange} style={{ width: 200, direction: 'rtl', padding: 8, clear: 'both', marginBottom: 5 }} />
                                                <Input type="text" name="Region" id="Region" placeholder="منطقه" value={this.state.Region} onChange={this.handleInputChange} style={{ width: 200, direction: 'rtl', padding: 8 }} />
                                            </Col>
                                        </Row>
                                    </Container>

                                    <div style={{ height: 20, padding: 10 }} >{this.state.showLoading ? <Spinner color="primary" size="lg" /> : null}</div>

                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '1%' }}></th>
                                                <th style={{ width: '59%', textAlign: 'right' }}>نام و نام خانوادگی</th>
                                                <th style={{ width: '22%', textAlign: 'right' }}>منطقه</th>
                                                <th style={{ width: '6%' }}>مشاهده</th>
                                                <th style={{ width: '6%' }}>ویرایش</th>
                                                <th style={{ width: '6%' }}>حذف</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sellerList.map((item, i) => {
                                                return (
                                                    this.state.Family != '' ? (
                                                        item.family.includes(this.state.Family) ? (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <Icon icon={'caret-left'} size={16} color="#000" />
                                                                </td>
                                                                <td style={{ textAlign: 'right' }}>{item.name + '  ' + item.family}</td>
                                                                <td style={{ textAlign: 'right' }}>{item.region}</td>
                                                                <td>
                                                                    <a href="javascript:" onClick={() => this.showSeller(item.id)}><Icon icon={'search'} color="#828" size={20} /></a>
                                                                </td>
                                                                <td>
                                                                    <a href="javascript:" onClick={() => this.updateSeller(item.id)}><Icon icon={'pencil'} color="#ee5c19" size={20} /></a>
                                                                </td>
                                                                <td>
                                                                    <a href="javascript:" onClick={() => this.showConfirm(item.id)}><Icon icon={'trash'} color="#f00" size={20} /></a>
                                                                </td>
                                                            </tr>) : null
                                                    ) : (
                                                            this.state.Region != '' ? (
                                                                item.region.includes(this.state.Region) ? (
                                                                    <tr key={item.id}>
                                                                        <td>
                                                                            <Icon icon={'caret-left'} size={16} color="#000" />
                                                                        </td>
                                                                        <td style={{ textAlign: 'right' }}>{item.name + '  ' + item.family}</td>
                                                                        <td style={{ textAlign: 'right' }}>{item.region}</td>
                                                                        <td>
                                                                            <a href="javascript:" onClick={() => this.showSeller(item.id)}><Icon icon={'search'} color="#828" size={20} /></a>
                                                                        </td>
                                                                        <td>
                                                                            <a href="javascript:" onClick={() => this.updateSeller(item.id)}><Icon icon={'pencil'} color="#ee5c19" size={20} /></a>
                                                                        </td>
                                                                        <td>
                                                                            <a href="javascript:" onClick={() => this.showConfirm(item.id)}><Icon icon={'trash'} color="#f00" size={20} /></a>
                                                                        </td>
                                                                    </tr>) : null
                                                            )
                                                                : (<tr key={item.id}>
                                                                    <td>
                                                                        <Icon icon={'caret-left'} size={16} color="#000" />
                                                                    </td>
                                                                    <td style={{ textAlign: 'right' }}>{item.name + '  ' + item.family}</td>
                                                                    <td style={{ textAlign: 'right' }}>{item.region}</td>
                                                                    <td>
                                                                        <a href="javascript:" onClick={() => this.showSeller(item.id)}><Icon icon={'search'} color="#828" size={20} /></a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="javascript:" onClick={() => this.updateSeller(item.id)}><Icon icon={'pencil'} color="#ee5c19" size={20} /></a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="javascript:" onClick={() => this.showConfirm(item.id)}><Icon icon={'trash'} color="#f00" size={20} /></a>
                                                                    </td>
                                                                </tr>)
                                                        )
                                                )
                                            })}

                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />

                    {/* Delete Message Modal */}
                    <Modal
                        isOpen={this.state.confirmModalVisible}
                        style={customStyles}>
                        <div style={{ height: 115, border: '1px solid #ee2222' }}>
                            <Container>
                                <Row>
                                    <Col style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl', paddingTop: 20 }}>
                                        برای حذف این فروشنده مطمئن هستید؟
                            </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.handleDeleteRecord.bind(this)} style={{ width: 80, backgroundColor: '#880000', color: '#fff' }}>بله</Button></Col>
                                    <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.closeModal.bind(this)} style={{ width: 80, backgroundColor: 'gray', color: '#fff' }}>خیر</Button></Col>
                                </Row>
                            </Container>
                        </div>
                    </Modal>

                    {/* Permit Modal */}
                    <Modal
                        isOpen={this.state.permitModalVisible}
                        style={permitStyles}>
                        <div style={{ height: 275, border: '1px solid #ee2222', direction: 'rtl' }}>
                            <Container>
                                <Row>
                                    <Col style={{ alignSelf: 'right', textAlign: 'right', paddingTop: 20 }}>
                                        <Label>تغییر رمز</Label>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={4} style={{ textAlign: 'right' }} style={{ padding: 5 }}>
                                        <span style={{ fontSize: 14, fontWeight: 'bold', color: '#226622' }}>نام کاربری: {' '}{this.state.userId}</span>
                                    </Col>
                                    <Col xs={8} style={{ textAlign: 'right' }} style={{ padding: 5 }}>
                                        <span style={{ fontSize: '13pt' }}>{localStorage.getItem('userName')}</span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} style={{ textAlign: 'right' }} style={{ padding: 20 }}>
                                        <Form>
                                            <Container>
                                                <Row>
                                                    <Col sm={4} style={{ textAlign: 'right' }} style={{ padding: 5 }}>
                                                        <Label>رمز جدید: </Label>
                                                    </Col>
                                                    <Col sm={7} style={{ padding: 5 }}>
                                                        <Input type="password" name="Passwrd1" size="20" id="Passwrd1" onChange={this.onChangePassword1} style={{ direction: 'ltr' }} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={4} style={{ textAlign: 'right' }} style={{ padding: 5 }}>
                                                        <Label>تکرار رمز جدید:</Label>
                                                    </Col>
                                                    <Col sm={7} style={{ padding: 5 }}>
                                                        <Input type="password" name="Passwrd2" id="Passwrd2" size="20" onChange={this.onChangePassword2} style={{ direction: 'ltr' }} />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Form>
                                    </Col>
                                </Row>

                                <Row style={{ paddingTop: 20 }}>
                                    <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.onSubmit} style={{ width: 80, backgroundColor: '#009900', color: '#fff' }}>ثبت</Button></Col>
                                    <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.closePermitModal.bind(this)} style={{ width: 80, backgroundColor: 'gray', color: '#fff' }}>انصراف</Button></Col>
                                </Row>
                            </Container>
                        </div>
                    </Modal>
                </Container>

                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
            </div>);
    }
}

export default SellerList;