import React from 'react';
import Axios from 'axios';
import API_URL from '../Api/ApiUrl';
import Flexbox from 'flexbox-react';
import { Form, FormGroup, Label, Input, Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import Icon from "../Icons/icon";
import {
    BrowserView,
    MobileView,
    isMobile,
    isSafari
} from "react-device-detect";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import mapStyles from './mapStyles';
import './Seller.css';

class SellerView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showSuccess: false,
            showDanger: false,
            userId: 'ghaderi',
            name: '',
            family: '',
            gender: '1',
            mobile: '',
            shop: '',
            region: '',
            address: '',
            latitude: '',
            longitude: '',
            user_code: '',
            nationalCode: '',
            phoneNumber: '',
            isMale: true,
            gndrName: 'مرد',
            avatarSource: null,
            avatarBase64: null,
            validate: {
                nameState: '',
                familyState: '',
                mobileState: '',
                shopState: '',
                regionState: '',
                addressState: '',
            },
            itemList: [],
            selectedType: '',
            selectedItem: '',
            isSetCurrent: false,
            currentPosition: {
                lat: 0,
                lng: 0
            },
            mapCenter: {
                lat: 35.70092132333425,
                lng: 51.391260847449296
            },
            locations: [],
            isMarkerShown: false,
            isDataSaved: false,

            bTitle: '',
            products: '',
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ sellerId: id });
        Axios.get(API_URL + 'getSellerData/' + id)
            .then(response => {
                console.log(response.data);

                this.setState({ id: response.data.slr_id });
                this.setState({ name: response.data.slr_name });
                this.setState({ family: response.data.slr_family });
                this.setState({ gender: response.data.slr_gender });
                this.setState({ mobile: response.data.slr_mobile });
                this.setState({ shop: response.data.slr_shop });
                this.setState({ region: response.data.slr_region });
                this.setState({ address: response.data.slr_address });
                this.setState({ latitude: response.data.slr_latitude });
                this.setState({ longitude: response.data.slr_longitude });

                this.setState({ selectedType: response.data.slr_btype });
                this.setState({ selectedItem: response.data.slr_stype });

                this.setState({ itemList: response.data.slr_goods });

                this.state.itemList.forEach(element => {
                    this.setState({ products: [...this.state.products, ' - ' + element.title ] });
                });

                var bTitle = '';
                switch (response.data.slr_btype) {
                    case 1:
                        bTitle = 'مایحتاج روزانه';
                        break;
                    case 2:
                        bTitle = 'فروشگاهی';
                        break;
                    case 3:
                        bTitle = 'خدماتی';
                        break;
                    default:
                        bTitle = '';
                }
                this.setState({ bTitle: bTitle });

                this.setState(() => ({
                    isSetCurrent: true,
                    currentPosition: {
                        lat: parseFloat(response.data.slr_latitude),
                        lng: parseFloat(response.data.slr_longitude)
                    },
                    mapCenter: {
                        lat: parseFloat(response.data.slr_latitude),
                        lng: parseFloat(response.data.slr_longitude)
                    }                
                }));
            }).catch(error => {
                console.log(error);
            });
    }

    handleChange() {
        const { gender } = this.state;
        if (gender === 1) {
            this.setState({ gender: 2 });
            this.setState({ gndrName: 'زن' });
        }
        if (gender === 2) {
            this.setState({ gender: 1 });
            this.setState({ gndrName: 'مرد' });
        }
    }

    goToList() {
        const { history } = this.props;
        history.push('/seller-list');
    }

    render() {
        let ref = null;
        let MapWrapped = withScriptjs(withGoogleMap(props => (
            <GoogleMap
                ref="map"
                ref={(mapRef) => ref = mapRef}
                onCenterChanged={() => {
                    if (this.state.panelStatus === 1) {
                        var center = ref.getCenter().toJSON();
                        console.log(center)
                        this.lastCenter = center;
                    }
                }}

                isMarkerShown={this.state.isMarkerShown}
                currentLocation={this.state.mapCenter}

                defaultZoom={16}
                defaultCenter={this.state.mapCenter}
                options={{
                    styles: mapStyles,
                    mapTypeControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                    fullscreenControl: false,
                }}>

                <Marker
                    mapTypeControl={false}
                    position={this.state.mapCenter}
                    icon={{
                        scaledSize: new window.google.maps.Size(39, 56),
                        anchor: new window.google.maps.Point(20, 28),
                    }}
                />
            </GoogleMap>)
        ));

        return (
            <div style={{ textAlign: 'right', direction: 'rtl', paddingTop: 10 }}>
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Container>
                                        <Row>
                                            <Col xs="6">مشاهده اطلاعات فروشنده</Col>
                                            <Col xs="6" className="colCss">
                                                <div className="btnIndex" onClick={this.goToList.bind(this)}>
                                                    <Icon icon={'list2'} size={14} color="#fff" />
                                                    <span style={{ marginRight: 10, color: '#fff' }}>فهرست</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardHeader>
                                <CardBody>
                                    <Flexbox flexDirection="column" minWidth={isMobile ? '100vw' : '10vw'}>
                                        <Flexbox flexGrow={1} justifyContent="flex-end">
                                            <Flexbox flexDirection="row" style={{ width: '100%', padding: 20 }}>
                                                <div className="profileForm">
                                                    <Form>
                                                        <FormGroup>
                                                            <Label>نام:</Label>{'  '}
                                                            <strong>{this.state.name}</strong>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>نام خانوادگی:</Label>
                                                            <strong>{this.state.family}</strong>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>جنسیت:</Label>{'  '}
                                                            <strong>{this.state.gndrName}</strong>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>شماره همراه:</Label>{'  '}
                                                            <strong>{this.state.mobile}</strong>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Container>
                                                                <Row>
                                                                    <Col sm={12} style={{ textAlign: 'right', padding: 5 }}>
                                                                        <Label>نوع فعالیت:</Label>{'  '}
                                                                        <strong>{this.state.bTitle}</strong>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Container>
                                                                <Row>
                                                                    <Col sm={12} style={{ textAlign: 'right', padding: 5 }}>
                                                                        <Label>عنوان فعالیت:</Label>{'  '}
                                                                        <strong>{this.state.products}</strong>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>عنوان فروشگاه:</Label>{'  '}
                                                            <strong>{this.state.shop}</strong>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>محله، منطقه:</Label>
                                                            <strong>{this.state.region}</strong>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>آدرس:</Label>
                                                            <strong>{this.state.address}</strong>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>موقعیت جغرافیایی:</Label>
                                                        </FormGroup>
                                                    </Form>
                                                </div>
                                            </Flexbox>
                                        </Flexbox>
                                        <Flexbox flexGrow={1} flexDirection="column" justifyContent="center">
                                        <Container>
                                                <Row>
                                                    <Col xs={12} style={{ padding: 5 }}>
                                                        {/* map OK */}
                                                        <div style={{ height: 400 }}>
                                                            <MapWrapped
                                                                googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyAzo9Xzk5QwuAixqF8Kxdxp1zgMfL2DtKA&v=3.exp&libraries=geometry,drawing,places&language=fa'}
                                                                loadingElement={<div style={{ height: '100%' }} />}
                                                                containerElement={<div style={{ height: '100%' }} />}
                                                                mapElement={<div style={{ height: '100%' }} />} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Flexbox>
                                    </Flexbox>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default SellerView;